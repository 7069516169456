<template>
  <div class="d-flex-column main">
    <div class="flex-1 main">
      <div class="mw-50p ml-0 mt-0">
        <h3 class="mt-0">Find your place to get involved</h3>
        <!-- MAIN FILTER -->
        <el-form @submit.native.prevent>
          <el-input
            placeholder="Try your Association or Competition"
            v-model="searchInput"
            class="full-width"
          >
            <template slot="append">
              <button class="button-primary icon-wrapper" @click.prevent="fetchSearchData">
                <i class="icon-search"></i>
              </button>
            </template>
          </el-input>
        </el-form>
      </div>
      <div class="main padding-lr-10-sm-and-up">
        <!-- important: area for search results   -->
        <CompetitionCard
          v-for="(object, i) in competitions"
          :object="object"
          :key="`${object._id}+${i}`"
          @submit="submit(object)"
        />
      </div>
    </div>
    <div class="padding-lr-10-sm-and-up">
      <el-card class="mt-2 d-flex-column flex-0 align-text-center" v-if="cantFind">
        <div class="flex-1 align-text-center m-1">Can't find what you are looking for?</div>
        <el-button
          @click="handleCantFind"
          type="success"
          class="full-width button-light button-thin mw-300"
        >
          Get in contact
        </el-button>
      </el-card>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapGetters } from 'vuex';
import CompetitionCard from '@/components/card/CompetitionCard.vue';
import msg from '@/utils/constants/msg';

export default {
  name: 'CompSearch',
  components: {
    CompetitionCard,
  },
  props: {
    criteria: String,
    source: String,
  },
  mounted() {
    const data = this.$route.query.data;
    // convert from base64 to json
    if(data){
      const entity = JSON.parse(atob(data));
      this.cantFind = true;
      this.$store.commit('root/LOADING', true);
        this.$httptoken
          .post('/nrl/api/v1/portal-public/registration-team/search', { entity })
          .then((response) => {
            const result = response.data;
            if (result && result.data && result.data.length !== 0) {
              this.competitions = result.data.map(c => Object.assign({}, c, {
                entityType: 'competition',
                contact: c.association.contact,
              }));
            } else {
              this.competitions = [];
            }
            this.$store.commit('root/LOADING', false);
          })
          .catch(() => {
            this.$store.commit(
              'views/PUSH_NOTIFICATION',
              {
                msg: msg.error.apiError,
                type: 'warning',
              },
              { root: true },
            );
            this.competitions = [];
            this.$store.commit('root/LOADING', false);
          });
      
    }
    if (!this.current.type) {
      this.updateCurrent({ type: 'team' });
      this.$store.dispatch('registration/clearCurrent');
    }

    if (this.source) {
      // 'rugby-league' or 'touch-football'
      this.$store.dispatch('views/updateNationalOrg', this.source);
    }

    if (this.criteria) {
      this.searchInput = this.criteria;

      this.fetchSearchData();
    }
  },
  computed: {
    ...mapGetters('user', ['isAuthenticated']),
    ...mapGetters('registrationTeams', ['current']),
  },
  methods: {
    ...mapActions('registrationTeams', ['updateCurrent']),

    cloneDeep,
    handleCantFind() {
      // Expression of Interest for National Bodies
      this.$router.push({
        name: 'expressinterest',
        params: { entityType: 'national', entityId: 1 },
      });
    },
    regoOpen(object) {
      if (object.maxNoTeams) { return (object.maxNoTeams - object.noTeams > 0 && object.regoOpen); }
      return object.regoOpen;
    },
    submit(object) {
      this.updateCurrent({ entity: object });
      if (this.regoOpen(object) && this.isAuthenticated) {
        this.$router.push({ name: 'teamform' });
      } else if (this.regoOpen(object)) {
        this.$router.push({ name: 'loginrequired', params: { loginRedirect: '/register/teamform' } });
      } else {
        // Expression of Interest for Entities
        this.$router.push({
          name: 'expressinterest',
          params: { entityType: object.entityType, entityId: object._id },
        });
      }
    },
    fetchSearchData() {
      this.cantFind = true;

      const criteria = this.searchInput.split(',')[0];

      this.$store.commit('root/LOADING', true);

      this.$httptoken
        .post('/nrl/api/v1/portal-public/registration-team/search', { criteria })
        .then((response) => {
          const result = response.data;
          if (result && result.data && result.data.length !== 0) {
            this.competitions = result.data.map(c => Object.assign({}, c, {
              entityType: 'competition',
              contact: c.association.contact,
            }));
          } else {
            this.competitions = [];
          }
          this.$store.commit('root/LOADING', false);
        })
        .catch(() => {
          this.$store.commit(
            'views/PUSH_NOTIFICATION',
            {
              msg: msg.error.apiError,
              type: 'warning',
            },
            { root: true },
          );
          this.competitions = [];
          this.$store.commit('root/LOADING', false);
        });
    },
  },
  data() {
    return {
      cantFind: false, // After search has been conducted
      // Search
      searchInput: '',
      searchTimeout: null,
      competitions: [],
    };
  },
};
</script>

<style lang="scss">
.text-days::before {
  content: var(--content);
  color: var(--color);
  text-transform: uppercase;
  position: absolute;
  font-family: $fontRegular;
  font-size: 0.875rem;
  top: 10px;
  left: 15px;
  size: 0;
  z-index: 1;
}
</style>

<style lang="scss" scoped>
.append-icon-close {
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
  color: $secondary;
}

.has-value {
  // make it a cross
  ::v-deep i.icon-arrow-down {
    background: none;
  }

  i.icon-arrow-down {
    background: none;
  }

  ::v-deep .el-input__inner {
    height: calc(0.875rem + 26px);
    border-color: $secondary;
    color: $secondary;

    &::placeholder {
      color: $secondary !important;
    }
  }

  .el-input__inner {
    border-color: $secondary;

    &::placeholder {
      color: $secondary !important;
    }
  }
}

::v-deep .el-select {
  height: calc(0.875rem + 26px);
}

.el-input {
  .el-input__suffix {
    position: absolute;
    right: 8px;
    transition: 0.5s;
  }
  &.visible {
    .el-input__suffix {
      transform: rotate(-180deg);
    }
  }
}

.select-1 {
  position: relative;
  width: 100%;
  @media (min-width: $sm) {
    max-width: 250px;
  }
}

.select-2 {
  position: relative;
  width: 50%;
  @media (min-width: $sm) {
    max-width: 200px;
  }
}

.select-3 {
  position: relative;
  width: 50%;
  @media (min-width: $sm) {
    max-width: 200px;
  }
}

.filters {
  background-color: white;
  flex-wrap: wrap;
}

.min-1000 {
  min-height: 1400px;
}

::v-deep .el-tag {
  display: none;
}

::v-deep .el-input-group__append {
  padding: 0;
  border: 0;
}

.icon-wrapper {
  height: 40px;
  width: 55px;
}

.mw-50p {
  width: 100%;
  @media (min-width: 1263px) {
    max-width: 50%;
  }
}
</style>
