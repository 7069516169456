<template>
  <el-card>
    <div>
      <!-- COMPETITION -->
      <!-- BEFORE-EXPAND -->
      <div @click.prevent="switchExpanded" class="click-expand">
        <CardArrow :class="expanded ? 'expanded' : null" />
        <div class="pr-1">
          <LogoTitleSubtitle
            :title="object.name"
            :subtitle="object.association.name"
            :img="endpointParams.logo(object.association._id)"
          />
        </div>
      </div>

      <!-- AFTER-EXPAND -->
      <transition-expand>
        <div v-if="expanded" class="exis-expand">
          <p v-if="object.venue">{{ object.venue.name }} - {{ object.venue.address.suburb }}</p>
          <p>
            Association Contact
            <br />
            Name: {{ object.contact.name }}
            <br />
            Number:
            <a
              :href="
                object.contact.number && object.contact.number.startsWith('0')
                  ? `tel:+61${parseInt(object.contact.number.slice(1))}`
                  : `tel:+${parseInt(object.contact.number)}`
              "
            >
              {{ object.contact.number }}
            </a>
            <br />
            Email: <a :href="`mailto:${object.contact.email}`">{{ object.contact.email }}</a>
            <br />
            <span v-if="object.association && object.association.meta && object.association.meta.website && object.association.meta.website.length">
              Website: <a :href="object.association.meta.website" target="_blank"> {{object.association.meta.website}} </a>
            </span>
            <br />
            <span v-if="object.association && object.association.meta && object.association.meta.facebook && object.association.meta.facebook.length">
              Facebook: <a :href="getFacebookUrl(object.association.meta.facebook)" target="_blank"> {{object.association.meta.facebook}} </a>
            </span>
            <br />
          </p>
          <div class="details">
            <div v-for="(line, index) in details(object)" :key="index" class="item d-flex">
              <div class="list-item">{{ line.left }}</div>
              <div class="right">{{ line.right }}</div>
            </div>
          </div>
          <p v-if="regoClosed">
            Registration has closed, but please get in contact if you want to know when they’re open
            again!
          </p>
          <CardButtonGroup :object="object" @submit="submit" />
        </div>
      </transition-expand>
    </div>
  </el-card>
</template>

<script>
import moment from 'moment';
import LogoTitleSubtitle from '@/components/LogoTitleSubtitle.vue';
import CardAddress from '@/components/card/components/CardAddress.vue';
import CardButtonGroup from '@/components/card/components/CardButtonGroup.vue';
import CardArrow from '@/components/card/components/CardArrow.vue';
import TransitionExpand from '@/components/presentation/TransitionExpand.vue';
import { endpointParams } from '@/utils/constants/api';

export default {
  name: 'CompetitionCard',
  components: {
    TransitionExpand,
    CardArrow,
    CardButtonGroup,
    CardAddress,
    LogoTitleSubtitle,
  },
  props: {
    object: Object,
  },
  data() {
    return {
      expanded: false,
      endpointParams,
    };
  },
  computed: {
    regoClosed() {
      if (this.object.maxNoTeams) {
        return !(this.object.maxNoTeams - this.object.noTeams > 0 && this.object.regoOpen);
      }
      return !this.object.regoOpen;
    },
  },
  methods: {
    getFacebookUrl(url) {
      return `https://facebook.com/${url}`;
    },
    switchExpanded() {
      this.expanded = !this.expanded;
    },
    formatDate(timestamp) {
      return moment(timestamp).format('dddd MMMM D');
    },
    details(object) {
      const table = [];

      table.push({
        left: 'Type',
        right: 'Touch',
      });

      table.push({
        left: object.ageLvl || '',
        right: object.gender || '',
      });

      return table;
    },
    submit() {
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss" scoped>
.pr-1 {
  padding-right: 15px;
}

.click-expand {
  position: relative;
}

.custom-expand {
  /* fix for IE this is not displaying. */
  visibility: visible !important;
  position: static !important;
}

.details {
  .list-item {
    font-family: "RL2-Medium", Arial;
    flex: 1 0 auto;
  }
  .right {
    flex: 0 0 auto;
    width: 50%;
  }

  .item {
    width: 100%;
    padding: 18px 0;
    border-top: 1px solid #d5d5d5;
  }
}
</style>
